<template>
	<div class="mediaCoverage-box">    
        <!-- header组件     -->
		<vheader
        :navId=navId>
		</vheader>
        <!-- header组件     -->

		<!-- banner组件 -->
		<banner 
		:navName="navName"
		:navUrl="navUrl"
		></banner>
		<!-- banner组件 -->
		<div class="mediaCoverage-content">            
			<div class="title05">
				<h1>MEDIA REPORT</h1>
				<h2>媒体报道</h2>
			</div>
            <ul class="list">
                <div>
                    <li v-for="item in newsList" :key="item.id" @click="jump('',`${item.type}`)">
                    <div class="imgbox">
                        <div></div>
                        <img :src="item.imgUrl" :alt="item.name" @click="jump('',`${item.type}`)">
                    </div>
                    
                    <div class="right">
                        <p>{{item.name}}</p>
                        <p>{{item.time}}</p>
                        <p>{{item.word}}</p>
                    </div>
                </li>
                </div>
            </ul>  
            <el-pagination
            background
            layout="prev, pager, next"
            :total="16">
            </el-pagination>
        </div>
        <vfooter></vfooter>
        <GoTop/>
    </div>
</template>

<script>
import banner from '../../components/banner.vue'
import vheader from '../../components/vheader.vue'
import vfooter from '../../components/vfooter.vue'
import GoTop from '@/components/GoTop.vue'
export default { 
	name: 'mediaCoverage',
	data () {
		return{
            // banner
			navName:'媒体报道',
			navUrl:'mediaCoverage', 
            navId:5,
            // banner
            
            // 分页
            // currentPage:1, //初始页
            // pagesize:[10,6],
            // 分页
            rilist1:[
                [
                {listit:'2020年全国居民健康素养水平达23.15%',lishref:"#"},
                {listit:'癌症治疗新希望！我国首个自主研发...',lishref:"#"},
                {listit:'国家卫健委出台人感染动物源性流感...',lishref:"#"},
                {listit:'国家医保局发文，明确推进门诊费用...',lishref:"#"},
                {listit:'国家卫健委：加强儿童中医药服务 推...',lishref:"#"},
                {listit:'这家西南的医院用高品质医疗服务，建...',lishref:"#"},
                {listit:'“十四五”残疾人康复服务蓝图绘就',lishref:"#"},
                {listit:'《2021医生互联网诊疗行为洞察报告》...',lishref:"#"},
                {listit:'六大方面布局！国办发文推动公立医院...',lishref:"#"},
                {listit:'七大城市用户调研指出：智慧医疗未来...',lishref:"#"},
            ],[
                {listit:'中国人民解放军总医院院士合集（部分）',lishref:"#"},
                {listit:'U医公益行启动，让泌尿疾病患者在基层...',lishref:"#"},
                {listit:'第十八期“院士+”西部儿科医师培训班座...',lishref:"#"},
                {listit:'福建首例机器人无腹部切口大肠癌手术在...',lishref:"#"},
                {listit:'多位专家赴湖北十堰参加燃气爆炸紧急医...',lishref:"#"},
                {listit:'张楠：阿尔茨海默病患者护理更需专业指导',lishref:"#"},
                {listit:'第一届智慧医疗与医学人文高峰论坛暨医...',lishref:"#"},
                {listit:'提醒身边长痣的朋友，出现以下五种症状...',lishref:"#"},
                {listit:'“疫”路同行的夫妻档',lishref:"#"},
                {listit:'与北京社团合作，中国西部城市乌兰察布...',lishref:"#"},
            ],
            [
                {listit:'水培蔬菜社交网络走红 专家：较土培更...',lishref:"#"},
                {listit:'山药的做法 山药的功效与作用',lishref:"#"},
                {listit:'发青的鸡蛋黄致癌？ 变绿部分无毒，可...',lishref:"#"},
                {listit:'经常失眠，可能是体内缺什么？3种营养...',lishref:"#"},
                {listit:'你知道健康吃饭的黄金标准吗？',lishref:"#"},
                {listit:'5大习惯能『防癌』，很多人都忽视了',lishref:"#"},
                {listit:'每餐4-6小时的间隔期最符合人体消化需...',lishref:"#"},
                {listit:'晨起一杯水别这么喝，风险太大...教你...',lishref:"#"},
                {listit:'心脏有问题，为什么医生还建议让我多运...',lishref:"#"},
                {listit:'缺铁性贫血，补就完事？补血食物别踩坑',lishref:"#"},
                ],
                [
                {listit:'疾控专家邵一鸣对我国目前疫苗功效给出解读',lishref:"#"},
                {listit:'馒头、米饭哪个更容易升血糖？一对比，3餐...',lishref:"#"},
                {listit:'手抖也能通过外科手术治愈啦',lishref:"#"},
                {listit:'身体血糖高 多喝水好还是少喝水好？看完...',lishref:"#"},
                {listit:'练起来 打太极拳能减“游泳圈”',lishref:"#"},
                {listit:'【科学就医我行动】陈勇皮科问答 | 10.皮...',lishref:"#"},
                {listit:'维生素C有什么功效和副作用？',lishref:"#"},
                {listit:'“提高免疫力”，被过度夸大化，免疫平衡比“...',lishref:"#"},
                {listit:'胃癌如何选择最合适疗方案？CA最新刊文指...',lishref:"#"},
                {listit:'九价HPV疫苗为何“一针难求”',lishref:"#"},
                ],
                ],

            newsList:[
                {
                    type:'newscl1',
                    imgUrl:require('../../assets/img/mediaCoverage/mc01.jpg'),
                    name:'恒安康泰口腔会员权益线下服务站正式启动',
                    time:'恒安康泰新闻中心 2021-11-18',
                    word:'恒安康泰会员福利持续发送，口腔专业护理正式上线，专业服务覆盖全国。北京市、内蒙古自治区等部分省市用户已经实现线上预约，线下服务。会员尊享价199元/人，非会员特惠价399元/人。',
                },
                // {
                //     type:'newscl2',
                //     imgUrl:require('../../assets/img/mediaCoverage/mc02.png'),
                //     name:'恒安康泰欢祝祖国华诞，积极响应健康中国行动',
                //     time:'恒安康泰新闻中心 2021-09-30',
                //     word:'国民健康是近年国家一致大力倡导的方向，拥有健康体魄是拥有强国魂的基础前提。再愿祖国及全体华夏儿女拥有健康体魄，发展健康社会，壮大健康中国。恒安康泰积极关注健康行动，祝贺大家国庆佳节快乐！',
                // },
                {
                    type:'newscl3',
                    imgUrl:require('../../assets/img/mediaCoverage/mc03.jpg'),
                    name:'恒安康泰受邀参加中关村企业家交流活动',
                    time:'恒安康泰新闻中心 2021-09-30',
                    word:'北京市中关村海淀留学人员创业园作为中国最早的企业孵化器，容纳众多先进的高精尖技术及服务模式创新的企业，成功孵化上市企业，接纳扶持众多科技型企业发展，为入园企业提供优越政策资源条件，培植助力众多企业初创发展。',
                },
                {
                    type:'newscl4',
                    imgUrl:require('../../assets/img/mediaCoverage/mc04.jpg'),
                    name:'恒安康泰献礼西部民生，北京三甲名医问诊福利开启',
                    time:'恒安康泰新闻中心 2021-09-22',
                    word:'关爱西部企事业人医疗健康，鹿勤集团与恒安康泰携手为内蒙古地区企事业职工及家庭健康保驾护航。恒安康泰（北京）医疗健康科技将北京优质医疗资源转化为每一个西部家庭的健康守护，通过鹿勤集团送到每一位职工手中。',
                },
                {
                    type:'newscl5',
					imgUrl:require('../../assets/logo.png'),
                    // imgUrl:require('../../assets/img/mediaCoverage/mc05.jpg'),
                    name:'恒安康泰专业护士陪诊开放单项预约',
                    time:'恒安康泰新闻中心 2021-08-21',
                    word:'恒安康泰专业护理团队，持证护士专业人员为患者在就诊期间的陪诊、陪检服务，自2021年9月1日起，开启单项预约了。',
                },
                {
                    type:'newscl6',
                    imgUrl:require('../../assets/img/mediaCoverage/9c120258936b72aa5a4ee68e12300b96.jpg'),
                    name:'今天是这个特别的日子！',
                    time:'恒安康泰新闻中心 2021-08-19',
                    word:'他们争分夺秒与病魔搏斗他们废寝忘食跟死神抢人他们无私奉献护万家健康新冠肺炎疫情发生后无数的他们更是逆...',
                },
                {
                    type:'newscl7',
                    imgUrl:require('../../assets/img/mediaCoverage/mc06.jpg'),
                    name:'热烈祝贺恒安康泰入选“创客北京2021”海淀区复赛！',
                    time:'恒安康泰新闻中心 2021-08-11',
                    word:'经过与各参赛企业的激烈角逐，大赛评审组的精心评选，恒安康泰以优质的技术团队及切实的便民社会价值成功入围“创客北京2021"海淀区复赛！恒安康泰将以自主开发的互联网医疗辅助平台为核心，不断深入优质医疗资源对接，在便民利民的社会医疗辅助方面继续努力，不断进取，再创佳绩！',
                },
                {
                    type:'newscl8',
                    imgUrl:require('../../assets/img/mediaCoverage/mc07.jpg'),
                    name:'恒安康泰开通核酸检测预约通道，会员免费预约，可上门采集！',
                    time:'恒安康泰新闻中心 2021-08-06',
                    word:'近日，疫情多次在我国出现散点式爆发，不得不让民众再次提高警惕，做好疫情防控不容懈怠，而核酸检测则成为必不可缺的前卫措施。...',
                },
                {
                    type:'newscl9',
                    imgUrl:require('../../assets/logo.png'),
                    name:'中关村新谈——恒安康泰首印象',
                    time:'恒安康泰新闻中心 2021-07-19',
                    word:'入驻中关村创业园的盛浪还有余热，恒安康泰再次迎来中关村杂志的记者采访。那么近期中关村杂志新谈，又将以何种视角展现恒安康泰...',
                },
                {
                    type:'newscl10',
                    imgUrl:require('../../assets/img/mediaCoverage/cfa3035f4e18e94566297a7080d13a40.jpg'),
                    name:'【冠以时代底色缅怀历史，争做行业翘楚领航未来】恒安康泰致敬建党一百周年',
                    time:'恒安康泰新闻中心 2021-07-01',
                    word:'百年前，中国共产党在黑暗中诞生，在苦难中成长，在挫折中奋起，在奋斗中壮大，从一个只有50多名党员的组织，发展成为世界上最大的马克思主义执政党...',
                },
                {
                    type:'newscl11',
                    imgUrl:require('../../assets/img/mediaCoverage/36dc266ae9604be9427eacce1da48633.jpeg'),
                    name:'【庆祝建党100周年】恒安康泰已申报参展2021年全国大众创业万众创新活动周北京会场展示项目',
                    time:'恒安康泰新闻中心 2021-06-30',
                    word:'根据国家发展改革委有关部署，2021年全国大众创业万众创新活动周北京会场（下称“双创周北京会场”）已启动筹备工作，按照北京会场筹备工作的有关安排...',
                },
                {
                    type:'newscl12',
                    imgUrl:require('../../assets/img/mediaCoverage/f2873549d596f744336b996d9b089d46.jpg'),
                    name:'项目服务——解读恒安康泰医疗健康第二谈',
                    time:'恒安康泰新闻中心 2021-06-24',
                    word:'作为第一家专注于服务西部地区企事业人的跨省市、集合线上便捷就医和线下温暖服务为一体的互联网+医疗+人文关怀的综合型互联网医疗服务企业...',
                },
                {
                    type:'newscl13',
                    imgUrl:require('../../assets/img/mediaCoverage/5d146a990cb31f1edb1e5ed6864435f2.jpg'),
                    name:'解读恒安康泰之直击“三化”服务优势',
                    time:'恒安康泰新闻中心 2021-06-21',
                    word:'为积极响应国家补充医疗政策，让每一位大众拥有赴京就医的便捷通道，恒安康泰致力于为大众带来便捷、细致的服务，实现赴京就医无忧愁...',
                },
                {
                    type:'newscl14',
                    imgUrl:require('../../assets/img/mediaCoverage/d95269a3177cfd584942b7e89faf253b.jpg'),
                    name:'来自中关村创业园的一封信——致恒安康泰（北京）医疗健康科技有限责任公司',
                    time:'恒安康泰新闻中心 2021-06-09',
                    word:'欢迎您来到北京市留学人员海淀创业园开始您的创业历程。北京市留学人员海淀创业园(以下简称*海淀创业园”)成立于1997 年 10 月,是北京市第一家专门为留...',
                },
                {
                    type:'newscl15',
                    imgUrl:require('../../assets/img/mediaCoverage/7bb443fcf1657ef5983992a2921184cb.jpg'),
                    name:'热烈祝贺恒安康泰（北京）医疗健康科技有限责任公司成功加入中关村海淀区留学人员创业园！',
                    time:'恒安康泰新闻中心 2021-06-09',
                    word:'厚实的脚印，承载着更多的社会责任。热烈祝贺恒安康泰（北京）医疗健康有限责任公司于2021年5月25日成功入驻北京市中关村...',
                },
                {
                    type:'newscl16',
                    imgUrl:require('../../assets/img/mediaCoverage/veer-312539524.jpg'),
                    name:'恒安康泰接受北京中关村杂志记者采访——对话恒安康泰',
                    time:'恒安康泰新闻中心 2021-06-08',
                    word:'近日北京中关村杂志记者采访恒安康泰（北京）医疗健康科技，了解“一个有温度的互联网医疗平台”',
                }
            ]    
        }
	},
	props: {
	},
	methods:{
		// handleSizeChange(val) {
        //     this.pagesize = val;
        // },
        // handleCurrentChange(val) {
        //     this.currentPage = val;
        // }
        jump(jumpindex,type){
      if(type == 'newscl1'){
        this.$router.replace({name:'newscl1'})
        
      }else if(type == 'newscl2'){
        this.$router.push('/newscl2')
      }
      else if(type == 'newscl3'){
        this.$router.push('/newscl3')
      }else if(type == 'newscl4'){
        this.$router.push('/newscl4')
      }
      else if(type == 'newscl5'){
        this.$router.push('/newscl5')
      }
      else if(type == 'newscl6'){
        this.$router.push('/newscl6')
      }
      else if(type == 'newscl7'){
        this.$router.push('/newscl7')
      }
      else if(type == 'newscl8'){
        this.$router.push('/newscl8')
      }
      else if(type == 'newscl9'){
        this.$router.push('/newscl9')
      }
      else if(type == 'newscl10'){
        this.$router.push('/newscl10')
      }else if(type == 'newscl11'){
        this.$router.push('/newscl11')
      }else if(type == 'newscl12'){
        this.$router.push('/newscl12')
      }else if(type == 'newcl13'){
        this.$router.push('/newcl13')
      }
      else if(type == 'newscl14'){
        this.$router.push('/newscl14')
      }else if(type == 'newscl15'){
        this.$router.push('/newscl15')
      }else if(type == 'newscl16'){
        this.$router.push('/newscl16')
      }
    }
	},
	components:{
		banner,
		vheader,
		vfooter,
        GoTop
	},
}
</script>

<style scoped lang="less">
// pc端样式
@media screen and (min-width:750px){
    .mediaCoverage-box{
        .mediaCoverage-content{
            width: 1200Px;
            margin: 37Px auto 80Px;
            .title05{
                :first-child{
                    font-size: 42Px;
                    color: #ddd;
                    text-align: center;
                }
                :nth-child(2){
                    width: 100%;
                    text-align: center;
                    margin:0 auto 31Px;
                    font-size: 22Px;
                    padding-bottom: 20Px;
                    color: #222;
                    border-bottom: 1Px solid #ccc;
                }
            }                
            .list{
                
                &>div{
                    &>li{
                    width: 100%;
                    border-bottom: 1Px solid #ccc;
                    padding-bottom: 30Px;
                    margin-bottom: 30Px;
                    display: flex;
                    }
                    
                    .imgbox{
                        width: 250Px;
                        height: 150Px;
                        position: relative;
                        background-color: #ccc;
                        img{
                            width: 250Px;
                            height: 150Px;
                            
                        }
                        div{
                            width: 250Px;
                            height: 150Px;
                            position: absolute;
                            transition: all 0.3s;
                            cursor: pointer;
                            &:hover{                    
                                background:#000 url(../../assets/img/all/lianjie.png) no-repeat center ;
                                opacity: 0.7;                            
                            }                                
                        }
                    }
                        
                    .right{
                        margin-left: 25Px;
                        :first-child{
                            font-size: 18Px;
                            color: #333;
                            cursor: pointer;
                            &:hover{
                                color: #093C6C;
                            }
                        }
                        :nth-child(2){
                            font-size: 14Px;
                            margin: 15Px 0 10Px;
                            color: #bbb;
                        }
                        :nth-child(3){
                            font-size: 14Px;
                            line-height: 30Px;
                            color: #888;
                        }
                    }
                }
                .listNewsRight {
                    width: 300Px;
                    height: 2260Px;
                    padding: 25Px 0 0;
                    background-color: #f7f8f9;
                    display: inline-block;
                    vertical-align: text-top;
                    position: relative;
                    font-size: 14Px;
                    border-right: 1Px solid #999;
                    border-top: 1Px solid #999;
    .stateNewsRighth2 {
    margin: 0;
    font-size: 26Px;
    font-weight: bold;
    text-align: center;
    color: #333;
    line-height: 40Px;
    margin-bottom: 30Px;
}
.stateNewsRighth3 {
    font: 700 16Px/46Px "微软雅黑";
    text-align: left;
    padding-left: 20Px;
    color: #333;
    line-height: 24Px;
    margin-top: 24Px;
    margin-bottom: 0;
}
.stateNewsRightUl {
    padding: 0 20Px 5Px;
    margin-bottom: 0;
    .stateNewsRightAdverts {
    line-height: 21Px;
    padding: 5Px 0Px 5Px 15Px;
    padding-left: 12Px;
    font-size: 14Px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    i{
        display: block;
    width: 6Px;
    height: 6Px;
    border-radius: 6Px;
    background-color: #093C6C;
    position: absolute;
    top: 10Px;
    left: 0;
    }
}
}
.stateNewsRight_h3_hr {
    border: none;
    width: 90%;
    height: 2Px;
    background-color: #093C6C;
    margin: 17Px auto;
}
.guowuImg {
    display: block;
    width: 280Px;
    margin: 0 auto;
    padding-bottom: 10Px;
}
}
            }
            /deep/ .el-pagination{
                display: flex;
                justify-content: center;
            }
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
                background-color: #093C6C;
            }
        }
    }
}

// 移动端样式
@media screen and (max-width:750px){    
    .mediaCoverage-box{
        .mediaCoverage-content{
            width: 95%;
            margin: 15px auto 35px;
            .title05{
				width: 95%;
				:first-child{
					font-size: 25px;
					color: #ddd;
					text-align: center;
				}
				:nth-child(2){
					width: 65px;
					font-size: 16px;
					color: #222;
					margin:0 auto 19px;
					padding-bottom: 5px;
					border-bottom: 1px solid #093C6C;
				}
			}ul{
                li{
                    border-bottom: 1Px solid #ccc;
                    padding-bottom: 8Px;
                    margin-bottom: 18Px;
                    display: flex;
                    .imgbox{
                        width: 250Px;
                        position: relative;
                        img{
                            width: 150Px;
                            height: 92Px;                            
                        }
                    }
                        
                    .right{
                        margin-left: 15Px;
                        :first-child{
                            font-size: 15Px;
                            color: #333;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                        :nth-child(2){
                            font-size: 13Px;
                            margin: 9Px 0;
                            color: #ccc;
                        }
                        :nth-child(3){
                            font-size: 14Px;
                            color: #999;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            /deep/ .el-pagination{
                display: flex;
                justify-content: center;
            }
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
                background-color: #093C6C;
            }
        }
    }
}
</style>
